const commonStyles = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1.05em',
  lineHeight: '170%',
};

export const colors = {
  light: {
    background: '#FBFBFB',
    text: '#444',
    primaryButtonText: '#fff',
    buttonText: '#266D78',
    buttonBorder: '#266D78',
    cardBG: '#ebebeb',
    primary: `#266D78`,
    twitterColor: '#1da1f2',
    facebookColor: '#3b5998',
    redditColor: '#FF5700',
    linkedInColor: '#0072b1',
    pinterestColor: '#BD081C',
  },
  dark: {
    background: '#353535',
    text: '#ababab',
    primaryButtonText: '#fff',
    buttonText: '#266D78',
    buttonBorder: '#266D78',
    cardBG: '#222222',
    primary: `#266D78`,
    twitterColor: '#1da1f2',
    facebookColor: '#3b5998',
    redditColor: '#FF5700',
    linkedInColor: '#0072b1',
    pinterestColor: '#BD081C',
  },
};

const commonLightColors = {
  background: `var(--color-background, ${colors.light.background})`,
  text: `var(--color-text, ${colors.light.text})`,
  buttonText: colors.light.buttonText,
  buttonBorder: colors.light.buttonBorder,
  cardBG: colors.light.cardBG,
  twitterColor: colors.light.twitterColor,
  facebookColor: colors.light.facebookColor,
  redditColor: colors.light.redditColor,
  linkedInColor: colors.light.linkedInColor,
  pinterestColor: colors.light.pinterestColor,
};

const commonDarkColors = {
  background: `var(--color-background, ${colors.dark.background})`,
  text: `var(--color-text, ${colors.dark.text})`,
  buttonText: colors.dark.buttonText,
  buttonBorder: colors.dark.buttonBorder,
  cardBG: colors.dark.cardBG,
  twitterColor: colors.dark.twitterColor,
  facebookColor: colors.dark.facebookColor,
  redditColor: colors.dark.redditColor,
  linkedInColor: colors.dark.linkedInColor,
  pinterestColor: colors.dark.pinterestColor,
};

export const theme: { [key: string]: any } = {
  default: {
    light: {
      ...commonStyles,
      ...commonLightColors,
      primary: `tomato`,
      primaryButtonText: 'gray',
    },
    dark: {
      ...commonStyles,
      ...commonDarkColors,
      primary: 'tomato',
      primaryButtonText: 'gray',
    },
  },
};
