import React, { ReactNode, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { SiteContextProvider, SiteContext } from '.';
import { theme } from '../theme';

interface Props {
  children: ReactNode;
}

const ThemeComponent = ({ children }: Props) => {
  const { globalState } = useContext(SiteContext);
  return (
    <ThemeProvider theme={theme[globalState.theme][globalState.themeMode]}>
      {children}
    </ThemeProvider>
  );
};
const SiteWrapper = ({ children }: Props) => {
  return (
    <SiteContextProvider>
      <ThemeComponent>{children}</ThemeComponent>
    </SiteContextProvider>
  );
};

export default SiteWrapper;
