module.exports = [{
      plugin: require('../../gatsby-theme-stori/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/stori","mdx":false,"siteTitle":"Stori Theme","themeColor":"blue","showColorPicker":true},
    },{
      plugin: require('../../gatsby-theme-phoney/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/phoney","siteTitle":"Phoney Theme"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"linkImagesToOriginal":false}}],"defaultLayouts":{"default":"/Users/adamwebster/Sites/gatsby-themes/gatsby-theme-tabini/src/components/Layout/PageLayout.tsx"}},
    },{
      plugin: require('../../gatsby-theme-tabini/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/tabini","contentful":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-28069657-11"},
    }]
