const commonStyles = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1.05em',
  lineHeight: '170%',
};

export const colors = {
  light: {
    background: '#FBFBFB',
    text: '#444',
    primaryButtonText: '#fff',
    buttonText: '#266D78',
    buttonBorder: '#266D78',
    cardBG: '#ebebeb',
    primary: `#266D78`,
    twitterColor: '#1da1f2',
    facebookColor: '#3b5998',
    redditColor: '#FF5700',
    linkedInColor: '#0072b1',
    pinterestColor: '#BD081C',
  },
  dark: {
    background: '#353535',
    text: '#ababab',
    primaryButtonText: '#fff',
    buttonText: '#266D78',
    buttonBorder: '#266D78',
    cardBG: '#222222',
    primary: `#266D78`,
    twitterColor: '#1da1f2',
    facebookColor: '#3b5998',
    redditColor: '#FF5700',
    linkedInColor: '#0072b1',
    pinterestColor: '#BD081C',
  },
};

export const primaryColors: { [key: string]: any } = {
  default: {
    light: {
      primary: `#266D78`,
      primaryButtonText: '#fff',
    },
    dark: {
      primary: `#359dad`,
      primaryButtonText: '#fff',
    },
  },
  red: {
    light: {
      primary: `#C93131`,
      primaryButtonText: '#fff',
    },
    dark: {
      primary: `#FF7070`,
      primaryButtonText: '#5C0000',
    },
  },
  blue: {
    light: {
      primary: `#3160c9`,
      primaryButtonText: '#fff',
    },
    dark: {
      primary: `#84A1E1`,
      primaryButtonText: '#353535',
    },
  },
  purple: {
    light: {
      primary: `#8131c9`,
      primaryButtonText: '#fff',
    },
    dark: {
      primary: `#c788ff`,
      primaryButtonText: '#353535',
    },
  },
  gray: {
    light: {
      primary: `#464646`,
      primaryButtonText: '#fff',
    },
    dark: {
      primary: `#efefef`,
      primaryButtonText: '#2B2B2B',
    },
  },
};

const commonLightColors = {
  background: `var(--color-background, ${colors.light.background})`,
  text: `var(--color-text, ${colors.light.text})`,
  buttonText: colors.light.buttonText,
  buttonBorder: colors.light.buttonBorder,
  cardBG: colors.light.cardBG,
  twitterColor: colors.light.twitterColor,
  facebookColor: colors.light.facebookColor,
  redditColor: colors.light.redditColor,
  linkedInColor: colors.light.linkedInColor,
  pinterestColor: colors.light.pinterestColor,
};

const commonDarkColors = {
  background: `var(--color-background, ${colors.dark.background})`,
  text: `var(--color-text, ${colors.dark.text})`,
  buttonText: colors.dark.buttonText,
  buttonBorder: colors.dark.buttonBorder,
  cardBG: colors.dark.cardBG,
  twitterColor: colors.dark.twitterColor,
  facebookColor: colors.dark.facebookColor,
  redditColor: colors.dark.redditColor,
  linkedInColor: colors.dark.linkedInColor,
  pinterestColor: colors.dark.pinterestColor,
};

export const theme: { [key: string]: any } = {
  default: {
    light: {
      ...commonStyles,
      ...commonLightColors,
      primary: `var(--color-primary, #266D78)`,
      primaryButtonText: 'var(--color-button-text-primary, #fff)',
    },
    dark: {
      ...commonStyles,
      ...commonDarkColors,
      primary: 'var(--color-primary, #359dad)',
      primaryButtonText: 'var(--color-button-text-primary, #fff)',
    },
  },
  red: {
    light: {
      ...commonStyles,
      ...commonLightColors,
      primary: 'var(--color-primary, #C93131)',
      primaryButtonText: 'var(--color-button-text-primary, #fff)',
    },
    dark: {
      ...commonStyles,
      ...commonDarkColors,
      primary: 'var(--color-primary, #FF7070)',
      primaryButtonText: 'var(--color-button-text-primary, #5C0000)',
    },
  },
  purple: {
    light: {
      ...commonStyles,
      ...commonLightColors,
      primary: '#8131c9',
      primaryButtonText: 'var(--color-button-text-primary, #fff)',
    },
    dark: {
      ...commonStyles,
      ...commonDarkColors,
      primary: 'var(--color-primary, #c788ff)',
      primaryButtonText: 'var(--color-button-text-primary, #353535)',
    },
  },
  blue: {
    light: {
      ...commonStyles,
      ...commonLightColors,
      primary: 'var(--color-primary, #3160c9)',
      primaryButtonText: 'var(--color-button-text-primary, #fff)',
    },
    dark: {
      ...commonStyles,
      ...commonDarkColors,
      primary: 'var(--color-primary, #84A1E1)',
      primaryButtonText: 'var(--color-button-text-primary, #353535)',
    },
  },
  gray: {
    light: {
      ...commonStyles,
      ...commonLightColors,
      primary: 'var(--color-primary, #464646)',
      primaryButtonText: 'var(--color-button-text-primary, #fff)',
    },
    dark: {
      ...commonStyles,
      ...commonDarkColors,
      primary: 'var(--color-primary, #efefef)',
      primaryButtonText: 'var(--color-button-text-primary, #2B2B2B)',
    },
  },
};

export const themeVariables = {
  values: {
    imageBorderRadius: '5px',
    avatarBorderRadius: '50%',
    borderRadius: '30px',
    cardBorderRadius: '5px',
  },
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '600px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
