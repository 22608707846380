/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import SiteWrapper from './src/context/SiteWrapper';
config.autoAddCss = false;

export const wrapRootElement = ({ element }) => {
  return <SiteWrapper>{element}</SiteWrapper>;
};
